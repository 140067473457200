import { useState, useEffect } from "react";
import axios from "../api/axios";

const useGetData = (url, refresh) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      await axios
        .get(url, {
          signal: controller.signal,
          crossDomain: true
        })
        .then((res) => {
          setData(res.data)
          setLoading(true)
        })
        .catch((err) => {
          if (!err?.message === "canceled") {
            console.error(err);
          }
        });
    };
    getData();
    
    return () => {
      controller.abort();
    };
  }, [url, refresh]);
  
  return {
    data,
    loading
  };
};

export default useGetData;
