import React from "react";

const LoginForm = (props) => {
  return (
    <section className="login-section">
      <div className="background-login"></div>
      <form onSubmit={props.HandleLogin} className="login-window">
        <h2>Sign In</h2>
        <div className="input-container">
          <label htmlFor="">Email</label>
          <input ref={props.usernameRef} type="text" value={'EDWARD'} />
        </div>
        <div className="input-container">
          <label htmlFor="">Password</label>
          <input ref={props.passwordRef} type="password" value={'PEMETEC01'} />
        </div>
        <button>Login</button>
      </form>
    </section>
  );
};

export default LoginForm;
