import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";

const HomeForm = (props) => {
  return (
    <section className="home-section">
      <div className="container">
        <button onClick={props.GoToSalesReport}>
          <FontAwesomeIcon icon={faChartSimple} className="icon" />
          Reporte de Ventas
        </button>
      </div>
    </section>
  );
};

export default HomeForm;
