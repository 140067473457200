import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/LayoutIndex";
import RequireAuth from "./components/RequireAuth";
import SalesReport from "./pages/SalesReport/SalesReportIndex";
import Home from "./pages/Home/HomeIndex";
import Login from "./pages/Login/LoginIndex";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Home />} />
          <Route path="/sales/report" element={<SalesReport />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
