import React from "react";
import HomeForm from "./HomeForm";
import { useNavigate } from "react-router-dom";

const HomeIndex = () => {
  const navigate = useNavigate();

  function GoToSalesReport() {
    navigate("/sales/report");
  }
  
  return <HomeForm GoToSalesReport={GoToSalesReport} />;
};

export default HomeIndex;
