import React, { useRef } from "react";
import LoginForm from "./LoginForm";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const LoginIndex = () => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const {setAuth } = useAuth();

  async function HandleLogin(e) {
    e.preventDefault();
    axios
      .post("user/auth", {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      })
      .then((res) => {
        setAuth({
          username: usernameRef.current.value,
          businessId: res.data.businessId,
          businessName: res.data.businessName,
        });
        navigate("/");
      })
      .catch((error) => {
        if (!error.message) {
          console.error("No server response");
        } else if (error?.response?.status === 401) {
          console.error("Username or password are wrong");
        }
      });
  }
  return (
    <LoginForm
      usernameRef={usernameRef}
      passwordRef={passwordRef}
      HandleLogin={HandleLogin}
    />
  );
};

export default LoginIndex;
