import { useState, useEffect, useRef } from "react";
import SalesReportForm from "./SalesReportForm";
import useAuth from "../../hooks/useAuth";
import useGetData from "../../hooks/useGetData";
import * as XLSX from "xlsx";

const SalesReportIndex = () => {
  const { auth } = useAuth();
  const businessName = auth?.businessName;
  const businessId = auth?.businessId;

  const [salesData, setSalesData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const limitRef = useRef(100);
  const [limit, setLimit] = useState(limitRef.current);
  const [dateFilter, setDateFilter] = useState({
    from: "",
    to: "",
  });

  const { data } = useGetData(
    `business/sales/tracking/${businessId}/1/${limit}`
  );

  function ObjectToArraysOfValues(array) {
    let newArrayOfValues = [];
    for (let i = 0; i < array.length; i++) {
      newArrayOfValues.push(Object.values(data[i]));
    }
    return newArrayOfValues;
  }

  function formatDate(date) {
    const cleanDate = new Date(date.replace("T00:00:00.000Z", ""));
    const day = cleanDate.getDate() + 1;
    const month = cleanDate.getMonth() + 1;
    const year = cleanDate.getFullYear();
    const newFormatDate = `${day}-${month}-${year}`;
    return newFormatDate;
  }

  function generateExcel() {
    const exceldata = [
      [
        "ID",
        "FECHA",
        "ITBIS",
        "SERVICIO",
        "EFECTIVO",
        "TARJETA",
        "OTROS PAGOS",
      ],
      ...ObjectToArraysOfValues(data),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(exceldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    XLSX.writeFile(workbook, `Reporte de Ventas - ${businessName}.xlsx`);
  }

  function totalDataSum(array) {
    let sum = {
      itbis: 0,
      servicio: 0,
      efectivo: 0,
      tarjetas: 0,
      otrospagos: 0,
    };
    array.forEach((arr) => {
      sum.itbis += arr?.itbis;
      sum.servicio += arr?.servicio;
      sum.efectivo += arr?.efectivo;
      sum.tarjetas += arr?.tarjetas;
      sum.otrospagos += arr?.otrospagos;
    });
    return sum;
  }

  function HandleSelectDateChange(e) {
    const date = new Date();
    const actualDay =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const actualMonth =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;

    if (e.target.value === "Hoy") {
      setDateFilter({
        from: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    }

    if (e.target.value === "Ayer") {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 1);
      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    }

    if (e.target.value === "Semana") {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 7);
      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    }

    if (e.target.value === "Mes") {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 30);
      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    }

    if (e.target.value === "Año") {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 365);
      const newDay =
        newDate.getDate() < 10
          ? `0${newDate.getDate()}`
          : `${newDate.getDate()}`;

      const newMonth =
        newDate.getMonth() + 1 < 10
          ? `0${newDate.getMonth() + 1}`
          : `${newDate.getMonth() + 1}`;

      setDateFilter({
        from: `${newDate.getFullYear()}-${newMonth}-${newDay}`,
        to: `${date.getFullYear()}-${actualMonth}-${actualDay}`,
      });
    }
  }

  function HandleLimitChange() {
    try {
      if (!isNaN(limitRef?.current?.value)) {
        document
          .getElementById("input-limit")
          .classList.remove("limit-input-invalid");
        setLimit(limitRef?.current?.value ? limitRef?.current?.value : 100);
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
      } else {
        document
          .getElementById("refresh-icon")
          .classList.toggle("animation-refresh-icon");
        document
          .getElementById("input-limit")
          .classList.add("limit-input-invalid");
      }
    } catch (error) {}
  }

  function validDateHandle(date) {
    const cleanDate = new Date(date.replace("T00:00:00.000Z", ""));
    const day = cleanDate.getDate() + 1;
    const month = cleanDate.getMonth() + 1;
    const year = cleanDate.getFullYear();
    const newFormatDate = new Date(`${year}-${month}-${day}`).getTime();
    return newFormatDate;
  }

  function HandleSalesDataDateChange() {
    let newSalesData = [];
    data.forEach((data) => {
      if (!(validDateHandle(dateFilter.from) <= validDateHandle(data.FECHA))) return
      if (!(validDateHandle(data.FECHA) <= validDateHandle(dateFilter.to))) return
        newSalesData.push(data);
    });
    return newSalesData;
  }

  useEffect(() => {
    if (data === null) return;
    setSalesData(data);
    setTotalSum(totalDataSum(salesData));
  }, [data]);

  useEffect(() => {
    if (data === null) return;
    setSalesData(HandleSalesDataDateChange());
    setTotalSum(totalDataSum(salesData));
  }, [dateFilter, data, totalDataSum]);

  return (
    <div>
      <SalesReportForm
        businessName={businessName}
        salesData={salesData}
        generateExcel={generateExcel}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        formatDate={formatDate}
        totalSum={totalSum}
        HandleSelectDateChange={HandleSelectDateChange}
        limitRef={limitRef}
        HandleLimitChange={HandleLimitChange}
      />
    </div>
  );
};

export default SalesReportIndex;
