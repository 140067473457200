import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const SalesReportForm = (props) => {
  return (
    <section className="report-section">
      <div className="container">
        <div className="table-heading">
          <h2>{props.businessName}</h2>
          <FontAwesomeIcon
            icon={faFileExcel}
            className="icon"
            onClick={props.generateExcel}
          />
          <div className="filters">
            <div className="input-container">
              <div>
                <label htmlFor="">Desde: </label>
                <input
                  type="date"
                  value={props.dateFilter.from}
                  onChange={(e) => {
                    props.setDateFilter({
                      ...props.dateFilter,
                      from: e.target.value,
                    });
                  }}
                />
                <select onChange={props.HandleSelectDateChange}>
                  <option value="Hoy">Hoy</option>
                  <option value="Ayer">Ayer</option>
                  <option value="Semana">Hace una semana</option>
                  <option value="Mes">Hace un mes</option>
                  <option value="Año">Hace un año</option>
                </select>
              </div>
              <div>
                <label htmlFor="">Hasta: </label>
                <input
                  type="date"
                  value={props.dateFilter.to}
                  onChange={(e) => {
                    props.setDateFilter({
                      ...props.dateFilter,
                      to: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="">Límite: </label>
              <input
                id="input-limit"
                className="limit-input" 
                type="text" 
                ref={props.limitRef}
                placeholder="100"
              />
              <FontAwesomeIcon
                id="refresh-icon" 
                className="limit-icon " 
                icon={faArrowsRotate}
                onClick={props.HandleLimitChange}
              />
              {/* <button 
                className="limit-btn"
                onClick={props.HandleLimitChange}
              >Actualizar</button> */}
            </div>
          </div>
        </div>
        <div className="table-content">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>FECHA</th>
                <th>ITBIS</th>
                <th>SERVICIO</th>
                <th>EFECTIVO</th>
                <th>TARJETA</th>
                <th>OTROS PAGOS</th>
              </tr>
            </thead>
            <tbody>
              {props?.salesData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{props.formatDate(data.FECHA)}</td>
                    <td>{data.itbis}</td>
                    <td>{data.servicio}</td>
                    <td>{data.efectivo}</td>
                    <td>{data.tarjetas}</td>
                    <td>{data.otrospagos}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="table-footer">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>TOTAL</th>
                <th>{props.totalSum?.itbis?.toFixed(2)}</th>
                <th>{props.totalSum?.servicio?.toFixed(2)}</th>
                <th>{props.totalSum?.efectivo?.toFixed(2)}</th>
                <th>{props.totalSum?.tarjetas?.toFixed(2)}</th>
                <th>{props.totalSum?.otrospagos?.toFixed(2)}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </section>
  );
};

export default SalesReportForm;
